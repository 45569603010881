<template>
  <div ref="host">
    <canvas :ref="canvasId" />
  </div>
</template>

<script>
import { fabric } from 'fabric';
// import { saveAs } from 'file-saver/FileSaver';
/* eslint-disable no-unused-vars*/
import Arrow from '../../js/imageEditor/arrow';

export default {
  name:  'Editor',
  props: {
    canvasWidth: {
      type:    Number,
      default: 0,
    },
    canvasHeight: {
      type:    Number,
      default: 0,
    },
    editorNumber: {
      type:    Number,
      default: 0,
    },
  },
  data() {
    return {

      modes: {
        none:      'None',
        crop:      'Crop',
        rectangle: 'Rectangle',
        circle:    'Circle',
        arrow:     'Arrow',
        text:      'Text',
        freeDraw:  'FreeDraw',
      },
      currentMode:            null,
      currentParams:          null,
      canvas:                 null,
      width:                  null,
      height:                 null,
      origImage:              null,
      activeDrawObject:       null,
      activeDrawObjectOrigin: null,
      isMouseDown:            false,
    };
  },
  computed: {
    canvasId() {
      return `c${this.editorNumber}`;
    },
  },
  mounted() {
    this.canvas = new fabric.Canvas(this.$refs[this.canvasId]);
    this.canvas.setDimensions({ width: this.canvasWidth, height: this.canvasHeight });
    this.canvas.backgroundColor = '#fff';
    this.canvas.selectionColor = 'rgba(0,0,0,0)';
    this.canvas.selectionLineWidth = 0;

    this.canvas.id = this.editorNumber;

    this.currentMode = this.modes.none;
    this.bindEvents();
  },
  methods: {
    bindEvents() {
      this.unbindEvents();

      this.canvas.on('mouse:down', o => {
        this.mouseDown(o.pointer);
      });

      this.canvas.on('mouse:move', o => {
        this.mouseMove(o.pointer);
      });

      this.canvas.on('mouse:up', o => {
        this.mouseUp(o.pointer);
      });
    },
    unbindEvents() {
      this.canvas.off('mouse:down');

      this.canvas.off('mouse:move');

      this.canvas.off('mouse:up');
    },
    mouseDown(e) {
      this.isMouseDown = true;
      switch (this.currentMode) {
        case this.modes.crop:
          this.startCropDraw(e.x, e.y);
          break;

        case this.modes.circle:
          this.startCircleDraw(e.x, e.y);
          break;

        case this.modes.rectangle:
          this.startRectDraw(e.x, e.y);
          break;

        case this.modes.arrow:
          this.startArrowDraw(e.x, e.y);
          break;

        case this.modes.text:
          this.startTextDraw(e.x, e.y);
          break;

        case this.modes.freeDraw:
          this.startFreeDraw(e.x, e.y);
          break;

        default:
          break;
      }
    },
    mouseMove(e) {
      if (!this.isMouseDown) {
        return;
      }

      switch (this.currentMode) {
        case this.modes.crop:
          this.updateCropDraw(e.x, e.y);
          break;

        case this.modes.circle:
          this.updateCircleDraw(e);
          break;

        case this.modes.rectangle:
          this.updateRectDraw(e.x, e.y);
          break;

        case this.modes.arrow:
          this.updateArrowDraw(e.x, e.y);
          break;

        default:
          break;
      }
    },
    mouseUp(e) {
      if (!this.isMouseDown) {
        return;
      }
      this.isMouseDown = false;
      switch (this.currentMode) {
        case this.modes.crop:
          this.endCropDraw(e.x, e.y);
          break;

        case this.modes.circle:
          this.endCircleDraw(e.x, e.y);
          break;

        case this.modes.rectangle:
          this.endRectDraw(e.x, e.y);
          break;

        case this.modes.arrow:
          this.endArrowDraw(e.x, e.y);
          break;

        default:
          break;
      }
    },
    addCommand(cmd) {
      switch (cmd.command) {
        case this.modes.crop:
          this.$context.events.notify('imageStudio.crop',
            {
              img:     this.origImage,
              context: this.$context,
              canvas:  this.canvas,
              params:  cmd.params,
              cid:     this.canvas.id,
            });
          break;

        case this.modes.circle:
          this.$context.events.notify('imageStudio.ellipse',
            {
              canvas: this.canvas,
              params: cmd.params,
              cid:    this.canvas.id,
            });
          break;

        case this.modes.rectangle:
          this.$context.events.notify('imageStudio.rect',
            {
              canvas: this.canvas,
              params: cmd.params,
              cid:    this.canvas.id,
            });
          break;

        case this.modes.arrow:
          this.$context.events.notify('imageStudio.arrow',
            {
              canvas: this.canvas,
              params: cmd.params,
              cid:    this.canvas.id,
            });
          break;

        case this.modes.text:
          this.$context.events.notify('imageStudio.text',
            {
              canvas: this.canvas,
              params: cmd.params,
              text:   cmd.text,
              cid:    this.canvas.id,
            });
          break;

        case this.modes.freeDraw:
          this.$context.events.notify('imageStudio.freeDraw',
            {
              canvas: this.canvas,
              params: cmd.params,
              points: cmd.points,
              cid:    this.canvas.id,
            });
          break;

        default:
          break;
      }
    },
    setImage(e, commands = []) {
      this.canvas.clear();

      const inst = this;
      const reader = new FileReader();

      reader.onload = event => {
        const imgObj = new Image();

        imgObj.src = event.target.result;

        imgObj.onload = () => {
          inst.origImage = new fabric.Image(imgObj);

          if (inst.canvas.width <= inst.origImage.width
          || inst.canvas.height <= inst.origImage.height) {
            const canvasAspect = inst.canvas.width / inst.canvas.height;
            const imgAspect = inst.origImage.width / inst.origImage.height;

            const scaleFactor = inst.canvas.height / inst.origImage.height;
            const top = 0;
            const left = -((inst.origImage.width * scaleFactor) - inst.canvas.width) / 2;

            inst.canvas.setBackgroundImage(
              inst.origImage, inst.canvas.renderAll.bind(inst.canvas), {
                top,
                left,
                scaleX: scaleFactor,
                scaleY: scaleFactor,
              },
            );

            inst.canvas.renderAll();
            this.bindEvents();
            this.$emit('image-loaded');
          } else {
            const center = inst.canvas.getCenter();

            inst.canvas.setBackgroundImage(
              inst.origImage, inst.canvas.renderAll.bind(inst.canvas), {
                top:     center.top,
                left:    center.left,
                originX: 'center',
                originY: 'center',
              },
            );

            inst.canvas.renderAll();
            this.bindEvents();
            inst.$emit('image-loaded');
          }
        };
      };
      reader.readAsDataURL(e.target.files[0]);
    },
    enableCrop() {
      this.canvas.isDrawingMode = false;
      this.endTextDraw();
      this.currentMode = this.modes.crop;
      this.canvas.defaultCursor = 'crosshair';
    },
    enableText(params) {
      this.canvas.isDrawingMode = false;
      this.currentMode = this.modes.text;
      this.currentParams = params;
      this.canvas.defaultCursor = 'auto';
    },
    enableArrow(params) {
      this.canvas.isDrawingMode = false;
      this.endTextDraw();
      this.currentMode = this.modes.arrow;
      this.currentParams = params;
      this.canvas.defaultCursor = 'auto';
    },
    enableRect(params) {
      this.canvas.isDrawingMode = false;
      this.endTextDraw();
      this.currentMode = this.modes.rectangle;
      this.currentParams = params;
      this.canvas.defaultCursor = 'auto';
    },
    enableEllipse(params) {
      this.canvas.isDrawingMode = false;
      this.endTextDraw();
      this.currentMode = this.modes.circle;
      this.currentParams = params;
      this.canvas.defaultCursor = 'auto';
    },
    enableFreeDraw(params) {
      this.endTextDraw();
      this.currentMode = this.modes.freeDraw;
      this.canvas.isDrawingMode = true;
      this.currentParams = params;
      this.canvas.freeDrawingBrush.color = this.currentParams.stroke;
      this.canvas.freeDrawingBrush.width = this.currentParams.strokeWidth;
      this.canvas.defaultCursor = 'auto';
    },
    clear() {
      // Remove only commands for this canvas
      const cmds = [];

      while (this.$context.history.length > 0) {
        const cmd = this.$context.history.pop();

        if (cmd.cid === this.canvas.id) {
          cmd.undo();
        } else {
          cmds.push(cmd);
        }
      }
      cmds.reverse();
      cmds.forEach(cmd => {
        this.$context.history.push(cmd);
      });

      // Canvas should be clear but we can clear it anyways.
      this.canvas.clear();
      this.canvas.renderAll();
    },
    refresh() {
      // console.log('canvasRefresh');
      this.canvas.renderAll();
    },
    saveImage(pageId) {
      // Set the background to null so we dont save the image

      const size = this.canvas.backgroundImage.width / this.canvas.width;

      this.canvas.setBackgroundImage(null);
      if (this.canvas.size() === 0) {
        return null;
      }

      const opts = {
        multiplier: size,
      };

      return this.dataURLtoBlob(this.canvas.toDataURL(opts));
    },
    dataURLtoBlob(dataurl) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new Blob([u8arr], { type: mime });
    },
    startCropDraw(x, y) {
      this.canvas.calcOffset();
      this.canvas.discardActiveObject();
      this.currentParams = {
        left:             x,
        top:              y,
        width:            0,
        height:           0,
        borderColor:      'black',
        hasControls:      false,
        fill:             '',
        hasRotatingPoint: false,
        lockUniScaling:   true,
        noScaleCache:     false,
        strokeUniform:    true,
      };
      this.activeDrawObject = new fabric.Rect(this.currentParams);
      // console.log(`Start Crop ${x}, ${y} ${this.canvas.width}, ${this.canvas.height}`);

      this.canvas.add(this.activeDrawObject);
      this.canvas.bringToFront(this.activeDrawObject);
      this.canvas.setActiveObject(this.activeDrawObject);
    },
    updateCropDraw(x, y) {
      // console.log(`UpdateCropDraw left,right = ${x}, ${y}`);
      let nx = x;
      let ny = y;

      if (nx > this.canvas.width) {
        nx = this.canvas.width;
      }

      if (ny > this.canvas.height) {
        ny = this.canvas.height;
      }

      if (nx < 0) {
        nx = 0;
      }

      if (ny < 0) {
        ny = 0;
      }

      const w = nx - this.activeDrawObject.left;
      let h = ny - this.activeDrawObject.top;

      if (!w || !h) {
        return;
      }

      if (w / h !== this.canvas.width / this.canvas.height) {
        h = (w * this.canvas.height) / this.canvas.width;
      }

      this.activeDrawObject.set('width', w).set('height', h);
      this.activeDrawObject.setCoords();
      this.canvas.renderAll();
      // console.log(`Update left,right = ${this.activeDrawObject.left}, ${this.activeDrawObject.top}`);
    },
    endCropDraw() {
      this.canvas.calcOffset();

      const coords = this.activeDrawObject.aCoords.tl;

      if (this.activeDrawObject.width < 0) {
        this.activeDrawObject.width *= -1;
        coords.x -= this.activeDrawObject.width;
      }

      if (this.activeDrawObject.height < 0) {
        this.activeDrawObject.height *= -1;
        coords.y -= this.activeDrawObject.height;
      }

      const newWidth = this.canvas.width
        / (this.activeDrawObject.width / this.canvas.backgroundImage.scaleX);
      const newHeight = this.canvas.height
        / (this.activeDrawObject.height / this.canvas.backgroundImage.scaleY);

      const newX = -((coords.x + -this.canvas.backgroundImage.left)
        / this.canvas.backgroundImage.scaleX) * newWidth;
      const newY = -((coords.y + -this.canvas.backgroundImage.top)
        / this.canvas.backgroundImage.scaleY) * newHeight;

      this.canvas.remove(this.activeDrawObject);
      this.canvas.discardActiveObject();

      this.$context.events.notify('imageStudio.crop',
        {
          canvas:  this.canvas,
          img:     this.origImage,
          cid:     this.canvas.id,
          context: this.$context,
          params:  {
            height: newWidth,
            width:  newHeight,
            left:   newX,
            top:    newY,
          },
        });

      this.canvas.renderAll();
    },
    startCircleDraw(x, y) {
      this.currentParams = {
        fill:                 'transparent',
        stroke:               this.currentParams.stroke,
        strokeWidth:          this.currentParams.strokeWidth,
        disableCircleEditing: false,
        left:                 x,
        top:                  y,
        rx:                   0,
        ry:                   0,
        transparentCorners:   true,
        hasBorders:           false,
        hasControls:          false,
        strokeUniform:        true,
        noScaleCache:         false,
        strokeDashArray:      false,
        hasRotatingPoint:     false,
        selectable:           false,
        lockMovementX:        true,
        lockMovementY:        true,
        hoverCursor:          'auto',
      };
      // console.log(`starting cicle ${x}, ${y}`);

      this.activeDrawObject = new fabric.Ellipse(this.currentParams);
      this.activeDrawObjectOrigin = {
        x,
        y,
      };

      this.canvas.add(this.activeDrawObject);
      this.canvas.bringToFront(this.activeDrawObject);
      this.canvas.setActiveObject(this.activeDrawObject);
    },
    updateCircleDraw(e) {
      this.canvas.selection = false;

      this.activeDrawObject.noScaleCache = false;
      this.activeDrawObject.strokeUniform = true;

      const pointer = this.canvas.getPointer(e);
      const rx = Math.abs(this.activeDrawObjectOrigin.x - pointer.x) / 2;
      const ry = Math.abs(this.activeDrawObjectOrigin.y - pointer.y) / 2;

      if (this.activeDrawObjectOrigin.x > pointer.x) {
        // console.log(`Setting X ${pointer.x}`);
        this.activeDrawObject.set({
          left: Math.abs(pointer.x),
        });
      }

      if (this.activeDrawObjectOrigin.y > pointer.y) {
        // console.log(`Setting Y ${pointer.y}`);
        this.activeDrawObject.set({
          top: Math.abs(pointer.y),
        });
      }

      this.activeDrawObject.set({
        rx,
        ry,
      });

      this.activeDrawObject.setCoords();
      this.canvas.renderAll();
    },
    endCircleDraw() {
      // console.log(`Endcircle ${JSON.stringify(this.activeDrawObject)}`);
      this.currentParams.rx = this.activeDrawObject.rx;
      this.currentParams.ry = this.activeDrawObject.ry;
      this.currentParams.left = this.activeDrawObject.left;
      this.currentParams.top = this.activeDrawObject.top;
      this.$context.events.notify('imageStudio.ellipse',
        {
          canvas: this.canvas,
          params: this.currentParams,
          cid:    this.canvas.id,
        });
      this.canvas.remove(this.activeDrawObject);
      this.canvas.requestRenderAll();
    },

    startRectDraw(x, y) {
      this.currentParams = {
        fill:                 'transparent',
        stroke:               this.currentParams.stroke,
        strokeWidth:          this.currentParams.strokeWidth,
        disableCircleEditing: false,
        top:                  y,
        left:                 x,
        rx:                   0,
        ry:                   0,
        strokeUniform:        true,
        noScaleCache:         false,
        strokeDashArray:      false,
        hasControls:          false,
        hasBorders:           false,
        selectable:           false,
        lockMovementX:        true,
        lockMovementY:        true,
        hoverCursor:          'auto',
      };

      this.activeDrawObject = new fabric.Rect(this.currentParams);
      // console.log('start Rect');
      this.canvas.add(this.activeDrawObject);
      this.canvas.bringToFront(this.activeDrawObject);
      this.canvas.setActiveObject(this.activeDrawObject);
    },
    updateRectDraw(x, y) {
      const w = x - this.activeDrawObject.left;
      const h = y - this.activeDrawObject.top;

      if (!w || !h) {
        return;
      }

      this.activeDrawObject.set('width', w).set('height', h);
      this.canvas.requestRenderAll();
    },
    endRectDraw(x, y) {
      this.updateRectDraw(x, y);
      this.currentParams.width = this.activeDrawObject.width;
      this.currentParams.height = this.activeDrawObject.height;
      this.currentParams.left = this.activeDrawObject.left;
      this.currentParams.top = this.activeDrawObject.top;
      this.$context.events.notify('imageStudio.rect',
        {
          canvas: this.canvas,
          params: this.currentParams,
          cid:    this.canvas.id,
        });
      this.canvas.remove(this.activeDrawObject);
      this.canvas.requestRenderAll();
    },
    startArrowDraw(x, y) {
      this.currentParams = {
        strokeWidth:        this.currentParams.strokeWidth,
        strokeDashArray:    false,
        fill:               this.currentParams.stroke,
        stroke:             this.currentParams.stroke,
        originX:            'center',
        originY:            'center',
        objectCaching:      false,
        perPixelTargetFind: true,
        hasControls:        false,
        hasBorders:         false,
        selectable:         false,
        lockMovementX:      true,
        lockMovementY:      true,
        hoverCursor:        'auto',
      };

      const points = [ x, y, x, y ];

      this.activeDrawObject = new fabric.LineArrow(points, this.currentParams);
      // console.log('start arrow');

      this.canvas.add(this.activeDrawObject);
      this.canvas.bringToFront(this.activeDrawObject);
      this.canvas.setActiveObject(this.activeDrawObject);
    },
    updateArrowDraw(x, y) {
      this.activeDrawObject.set({
        x2: x,
        y2: y,
      });
      this.activeDrawObject.setCoords();
      this.canvas.requestRenderAll();
    },
    endArrowDraw(x, y) {
      this.updateArrowDraw(x, y);

      this.currentParams.x1 = this.activeDrawObject.x1;
      this.currentParams.y1 = this.activeDrawObject.y1;
      this.currentParams.x2 = this.activeDrawObject.x2;
      this.currentParams.y2 = this.activeDrawObject.y2;
      this.$context.events.notify('imageStudio.arrow',
        {
          canvas: this.canvas,
          params: this.currentParams,
          cid:    this.canvas.id,
        });
      this.canvas.remove(this.activeDrawObject);
      this.canvas.requestRenderAll();
    },
    startTextDraw(x, y) {
      this.endTextDraw();

      const { placeholder } = this.currentParams;

      this.currentParams = {
        fill:             this.currentParams.fill,
        fontFamily:       this.currentParams.fontFamily,
        left:             x,
        top:              y,
        fontSize:         this.currentParams.fontSize,
        fontStyle:        this.currentParams.fontStyle,
        fontWeight:       this.currentParams.fontWeight,
        placeholder:      this.currentParams.placeholder,
        hasControls:      false,
        hasBorders:       false,
        selectable:       false,
        lockMovementX:    true,
        lockMovementY:    true,
        hasRotatingPoint: false,
      };

      this.activeDrawObject = new fabric.IText(placeholder, this.currentParams);

      this.activeDrawObject.selectionStart = 0;
      this.activeDrawObject.selectionEnd = this.activeDrawObject.text.length;
      this.canvas.add(this.activeDrawObject);
      this.canvas.setActiveObject(this.activeDrawObject);
      if (!this.activeDrawObject) {
        this.activeDrawObject = this.canvas.getActiveObject();
      }
      this.activeDrawObject.enterEditing();
      this.canvas.requestRenderAll();

      const inst = this;

      this.activeDrawObject.on('editing:exited', o => {
        inst.activeDrawObject.off('editing:exited');
        inst.endTextDraw();
      });

      document.addEventListener('keydown', this.handleTextEnterKey);
    },
    handleTextEnterKey(e) {
      if (e.key === 'Enter') {
        if (this.activeDrawObject !== null
              && this.activeDrawObject.text
              && this.activeDrawObject.isEditing) {
          this.endTextDraw();
        }
      }
    },
    endTextDraw() {
      if (this.activeDrawObject === null || !this.activeDrawObject.text) {
        return;
      }

      document.removeEventListener('keydown', this.handleTextEnterKey);
      this.activeDrawObject.off('editing:exited');

      if (this.activeDrawObject.text === '' || this.activeDrawObject.text === this.currentParams.placeholder) {
        // this.activeDrawObject.exitEditing();
        this.canvas.remove(this.activeDrawObject);
        this.canvas.discardActiveObject();
        this.activeDrawObject = null;
        this.canvas.renderAll();

        return;
      }

      // console.log(`${JSON.stringify(this.currentParams)}`);
      this.$context.events.notify('imageStudio.text',
        {
          canvas: this.canvas,
          params: this.currentParams,
          text:   this.activeDrawObject.text,
          cid:    this.canvas.id,
        });
      this.canvas.remove(this.activeDrawObject);
      this.activeDrawObject = null;
      this.canvas.isDrawingMode = false;
      this.canvas.discardActiveObject();
      this.canvas.renderAll();
    },

    startFreeDraw() {
      const inst = this;

      this.canvas.on('object:added', o => {
        inst.canvas.off('object:added');
        // console.log('freedraw add1');
        if (inst.canvas.isDrawingMode) {
          // console.log('freedraw add2');
          this.$context.events.notify('imageStudio.freeDraw',
            {
              canvas: this.canvas,
              points: o.target.path,
              cid:    this.canvas.id,
              params: {
                x:                o.target.pathOffset.x,
                y:                o.target.pathOffset.y,
                stroke:           o.target.stroke,
                strokeWidth:      o.target.strokeWidth,
                strokeLineCap:    'round',
                strokeLineJoin:   'round',
                strokeMiterLimit: 10,
                fill:             null,
                hasControls:      false,
                hasBorders:       false,
                selectable:       false,
                lockMovementX:    true,
                lockMovementY:    true,
                hoverCursor:      'auto',
              },
            });
          inst.canvas.isDrawingMode = false;
          inst.canvas.remove(o.target);
          inst.canvas.renderAll();
          inst.canvas.isDrawingMode = true;
        }
      });
    },
  },

};
</script>

<style scoped>

.pointerCursor{
  cursor:pointer;
}
.pointerAuto {
  cursor: auto;
}

.canvas-container > .canvas {
  cursor:crosshair;
}
</style>
