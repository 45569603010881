import Vue from 'vue';

export default {
  /**
   * Get an image
   *
   * @return {Array}
   */
  async loadImage(url) {
    try {
      const response = await Vue.axios.get(url, {
        responseType: 'arraybuffer',
      });

      const buffer = Buffer.from(response.data, 'binary').toString('base64');

      return `data:${response.headers[
        'content-type'
      ].toLowerCase()};base64,${buffer}`;
    } catch (err) {
      // console.log(`loadImage ${err}`);

      return '';
    }
  },

};
