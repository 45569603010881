var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-12"},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"10"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center primary white--text"},[_vm._v(" "+_vm._s(_vm.$t('userPage.title'))+" ")]),_c('v-card-text',[_c('v-row',{staticClass:"mt-4"},[_c('v-spacer'),_c('v-col',[_c('v-row',{staticClass:"justify-center"},[_c('v-spacer'),_c('v-col',[_c('v-avatar',{attrs:{"tile":true,"size":"150"}},[(_vm.user.image)?_c('img',{staticClass:"userAvatar",attrs:{"src":_vm.user.image}}):_c('img',{attrs:{"src":require("@/assets/user-logo.png")}})])],1),_c('v-spacer')],1),_c('v-row',[_c('v-spacer'),_c('v-col',[_c('ValidationProvider',{ref:"provider",attrs:{"name":"image","rules":"image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-btn',{attrs:{"text":"","depressed":"","block":"","small":"","color":"secondary"},on:{"click":_vm.pickFile}},[_vm._v(" "+_vm._s(_vm.$t('userPage.changeImgButton'))+" ")]),_c('input',{ref:"image",staticStyle:{"display":"none"},attrs:{"name":"image","type":"file","accept":"image/*"},on:{"change":_vm.onFilePicked}}),_c('span',{staticClass:"error--text text-caption"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('v-spacer')],1),_c('v-row',[_c('v-spacer'),_c('v-col',[(_vm.successImage)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.successImage)+" ")]):_vm._e(),(_vm.errorImage)?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.errorImage)+" ")]):_vm._e()]),_c('v-spacer')],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"align-center"},[_c('p',{staticClass:"text-h3 text-sm-left "},[_vm._v(" "+_vm._s(_vm.user.username)+" ")]),_c('p',{staticClass:"text-h6 text-sm-left "},[_vm._v(" "+_vm._s(_vm.user.email)+" ")]),_c('p',{staticClass:"text-sm-left "},[_vm._v(" "+_vm._s(_vm.getPermissionName(_vm.user.access_level))+" ")])]),_c('v-col',{attrs:{"cols":"3"}})],1),_c('v-row',[_c('v-spacer'),_c('v-col',[_c('v-btn',{staticClass:"grey lighten-3 button mx-1",on:{"click":function($event){return _vm.openChangePassword()}}},[_vm._v(" "+_vm._s(_vm.$t('userPage.changePasswordBtn'))+" ")])],1),_c('v-spacer')],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.editPasswordDialog),callback:function ($$v) {_vm.editPasswordDialog=$$v},expression:"editPasswordDialog"}},[_c('v-form',[_c('v-card',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-card-title',{staticClass:"white--text primary"},[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('userPage.changePassword.title'))+" ")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"oldPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":_vm.$t('userPage.changePassword.oldPassword'),"type":"password","color":"secondary"},model:{value:(_vm.changePassword.oldPassword),callback:function ($$v) {_vm.$set(_vm.changePassword, "oldPassword", $$v)},expression:"changePassword.oldPassword"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"newPassword","rules":"min:6|max:100|confirmed:password_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":_vm.$t('userPage.changePassword.newPassword'),"type":"password","color":"secondary"},model:{value:(_vm.changePassword.newPassword),callback:function ($$v) {_vm.$set(_vm.changePassword, "newPassword", $$v)},expression:"changePassword.newPassword"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required|max:20|alpha_num","name":"password_confirmation","vid":"password_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":_vm.$t('userPage.changePassword.confirmPassword'),"type":"password","color":"secondary"},model:{value:(_vm.changePassword.verifyPassword),callback:function ($$v) {_vm.$set(_vm.changePassword, "verifyPassword", $$v)},expression:"changePassword.verifyPassword"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary white--text","disabled":invalid},on:{"click":function($event){return handleSubmit(_vm.updatePassword)}}},[_vm._v(" "+_vm._s(_vm.$t('userPage.changePassword.submit'))+" ")]),_c('v-btn',{on:{"click":function($event){_vm.editPasswordDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('userPage.changePassword.cancel'))+" ")])],1)]}}])})],1)],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"color":"error"},model:{value:(_vm.errorDialog),callback:function ($$v) {_vm.errorDialog=$$v},expression:"errorDialog"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.error)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }