var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.isSlides)?_c('v-row',[_c('v-slide-group',{staticClass:"py-2",attrs:{"center-active":"","mandatory":"","min-height":"400"},scopedSlots:_vm._u([{key:"next",fn:function(){return undefined},proxy:true},{key:"prev",fn:function(){return undefined},proxy:true}],null,false,3510696319),model:{value:(_vm.currentFrame),callback:function ($$v) {_vm.currentFrame=$$v},expression:"currentFrame"}},_vm._l((_vm.items),function(item,index){return _c('v-slide-item',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var _obj;

var active = ref.active;return [_c('v-card',{staticClass:"ma-2",attrs:{"flat":"","tile":"","ripple":false},on:{"click":function($event){return _vm.selectItem(item)}}},[(_vm.isInImageStudio(item))?_c('v-btn',{staticClass:"white--text ml-2 mt-2",attrs:{"color":"secondary","small":"","fab":"","absolute":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" check ")])],1):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFullscreenIcon(item,index)),expression:"showFullscreenIcon(item,index)"}],staticClass:"white--text mt-2",attrs:{"color":"transparent","absolute":"","right":"","small":"","fab":""},on:{"click":[function($event){return _vm.launchFullscreen(item)},function($event){$event.stopPropagation();}]}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" fullscreen ")])],1),_c('v-img',{ref:("image_" + index),refInFor:true,class:[ _vm.$style.item,
                      ( _obj = {}, _obj[_vm.$style.selected] = _vm.isSelected(item.image), _obj )],attrs:{"min-width":_vm.sliderImageWidth(active),"min-height":_vm.sliderImageHeight(active),"max-width":_vm.sliderImageWidth(active),"max-height":_vm.sliderImageHeight(active),"src":_vm.getPreviewUrl(item.image)}})],1)]}}],null,true)})}),1),_c('v-slider',{attrs:{"thumb-label":"","max":_vm.items.length},model:{value:(_vm.currentFrame),callback:function ($$v) {_vm.currentFrame=$$v},expression:"currentFrame"}})],1):_vm._e(),(_vm.isTiles)?_c('v-row',{staticClass:"overflow-y-auto",class:_vm.$style.tiles,attrs:{"justify":"center"}},_vm._l((_vm.items),function(item,index){
                      var _obj;
return _c('v-col',{key:index,staticClass:"ma-1 pa-1",attrs:{"cols":_vm.tileColumnWidth}},[_c('v-card',{attrs:{"flat":"","tile":"","ripple":false},on:{"click":function($event){return _vm.selectItem(item)}}},[(_vm.isInImageStudio(item))?_c('v-btn',{staticClass:"white--text ma-2",attrs:{"color":"secondary","small":"","fab":"","absolute":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" check ")])],1):_vm._e(),_c('v-img',{class:[
            ( _obj = {}, _obj[_vm.$style.selected] = _vm.isSelected(item.image), _obj )],attrs:{"src":_vm.getPreviewUrl(item.image)}})],1)],1)}),1):_vm._e(),(_vm.isSlides)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-btn',{staticClass:"mx-2",class:_vm.$style.navigation,attrs:{"color":"grey lighten-2","fab":"","small":""},on:{"click":function($event){_vm.currentFrame=0}}},[_c('v-icon',[_vm._v("first_page")])],1),_c('v-btn',{staticClass:"mx-2",class:_vm.$style.navigation,attrs:{"id":"backBtn","color":"grey lighten-2","fab":"","small":""},on:{"click":function($event){_vm.currentFrame-=1}}},[_c('v-icon',[_vm._v("chevron_left")])],1),_c('v-text-field',{staticClass:"mx-1",class:[_vm.$style.fields, _vm.$style.inputFrame, _vm.$style.centeredInput],attrs:{"label":_vm.$t('capturePage.details.frameNumber'),"color":"grey darken-1","type":"number","solo":"","dense":""},model:{value:(_vm.textInputModel),callback:function ($$v) {_vm.textInputModel=$$v},expression:"textInputModel"}}),_c('v-btn',{staticClass:"mx-2",class:_vm.$style.navigation,attrs:{"id":"nextBtn","color":"grey lighten-2","fab":"","small":""},on:{"click":function($event){_vm.currentFrame+=1}}},[_c('v-icon',[_vm._v("chevron_right")])],1),_c('v-btn',{staticClass:"mx-2",class:_vm.$style.navigation,attrs:{"color":"grey lighten-2","fab":"","small":""},on:{"click":function($event){_vm.currentFrame=_vm.items.length-1}}},[_c('v-icon',[_vm._v("last_page")])],1)],1):_vm._e(),_c('FullscreenImage',{ref:"fullscreenImage",attrs:{"src":_vm.fullscreenImage.src,"img-width":_vm.fullscreenImage.width,"img-height":_vm.fullscreenImage.height},on:{"fullscreen-exit":_vm.fullscreenExit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }