<template>
  <v-container>
    <v-row class="flex"
           align="center"
           justify="center"
    />
    <v-row class="flex"
           align="center"
           justify="center"
    >
      <v-col
        cols="12"
        sm="4"
        class="pwd-reset-form"
      >
        <v-card elevation="6">
          <v-row class="flex"
                 align="center"
                 justify="center"
          >
            <v-col class="align-center justify-center"
                   cols="12"
            >
              <img
                src="@/assets/logo.png"
                class="image-pwd-reset"
              >
            </v-col>
          </v-row>
          <v-card-text class="pt-4">
            <div>
              <v-form
                v-model="valid"
                @keyup.native.enter="valid && submit($event)"
              >
                <v-text-field
                  v-model="password"
                  :rules="[passwordRules.required]"
                  type="password"
                  :label="$t('passwordResetPage.enterPassword')"
                />
                <v-text-field
                  v-model="passwordConfirm"
                  :rules="[passwordRules.required, passwordRules.emailMatch]"
                  type="password"
                  :label="$t('passwordResetPage.confirmPassword')"
                />
                <v-row
                  justify="center"
                  class="error-pwd-reset"
                >
                  <label
                    v-if="error"
                    class="error--text"
                  >
                    {{ error }}
                  </label>
                </v-row>
                <v-row justify="center">
                  <v-btn
                    :disabled="!valid"
                    class="secondary white--text"
                    @click="submit"
                  >
                    {{ $t('passwordResetPage.submit') }}
                  </v-btn>
                </v-row>
              </v-form>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userService from '../js/services/userService';
import authService from '../js/services/authService';
import { ACCOUNTS_PAGE } from '../js/router/pages';

export default {
  name: 'PasswordReset',
  data() {
    return {
      error:           '',
      valid:           false,
      password:        '',
      passwordConfirm: '',
      passwordRules:   {
        required:   value => !!value || this.$t('passwordResetPage.passwordValidate'),
        emailMatch: value => value === this.password || this.$t('passwordResetPage.passwordMatchValidate'),
      },
    };
  },
  methods: {
    /**
     * Submit reset request.
     */
    async submit() {
      this.error = '';
      try {
        const resetData = {};

        resetData.email = this.$route.query.email;
        resetData.pswd_reset_guid = this.$route.query.token;
        resetData.new_password = this.password;

        await userService.updateUserPassword(resetData, false);

        await authService.login(this.$route.query.username, this.password);

        this.$router.push({ name: ACCOUNTS_PAGE });
      } catch (res) {
        this.error = res && res.data ? res.data : this.$t('passwordResetPage.error');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/variables";
.pwd-reset-form {
  margin-top: 200px;
}

.error-pwd-reset {
  position: absolute;
  top: 360px;
  display: flex;
  width: 90%;
}
.image-pwd-reset {
  width: 313px;
  margin: 20px 40px 0;
}
</style>
