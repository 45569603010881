<template>
  <v-container class="pt-0 px-12">
    <v-row>
      <v-col>
        <h1 class="subHeader">
          {{ $t('imageStudioReviewPage.title') }}
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" />
      <v-col>
        <v-btn
          class="mx-2"
          @click="infoDialog=true"
        >
          {{ $t('imageStudioReviewPage.info') }}
        </v-btn>
        <v-btn
          class="mx-2"
          color="secondary"
          @click="downloadProject()"
        >
          {{ $t('imageStudioReviewPage.download') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col />
      <v-col>
        <v-img
          v-for="index in project.page_count"
          :key="index"
          :max-width="computePageDimensions()"
          :src="getPageUrl(index)"
          class="mb-5"
        >
          <v-hover v-slot="{ hover }">
            <v-expand-transition>
              <div style="height: 100%;">
                <v-btn
                  v-show="hover"
                  icon
                  x-large
                  color="white"
                  @click="downloadImage(index)"
                >
                  <v-icon>
                    cloud_download
                  </v-icon>
                </v-btn>
              </div>
            </v-expand-transition>
          </v-hover>
        </v-img>
      </v-col>
      <v-col />
    </v-row>
    <v-dialog
      v-model="infoDialog"
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h6 white--text primary">
          {{ $t('imageStudioReviewPage.infoTitle') }}
          <v-spacer />
          <v-btn
            icon
            dark
            @click="close()"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list-item-group
            color="primary"
          >
            <v-list>
              <v-list-item
                v-for="(capture, idx) in project.captures"
                :key="idx"
                @click="gotoCapture(idx)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ captureInfo(idx) }}
                    <v-list-item-title />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-item-group>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { saveAs } from 'file-saver/FileSaver';
import { CAPTURE_PAGE } from '../js/router/pages';
import imageStudioService from '../js/services/imageStudioService';
import config from '../js/config';

export default {
  name: 'ImageStudioReview',
  data() {
    return {
      project:    {},
      images:     [],
      infoDialog: false,
    };
  },
  async mounted() {
    await this.loadProject();
  },
  methods: {
    async loadProject() {
      try {
        this.project = await imageStudioService.getProjectInfo(
          this.$route.params.imageStudioId,
          false,
        );
      } catch (error) {
        // console.log(error);
        this.exportNotFound = true;
      }
    },
    computePageDimensions() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 1600;

        case 'sm':
          return 1600;

        case 'md':
          return 1600;

        case 'lg':
          return 1600;

        default:
          return 1600;

        case 'xl':
          return 1600;
      }
    },
    getPageUrl(pageNumber) {
      return `${config.apiUrl}api/image_studio/${this.project.id}/page?pageNumber=${pageNumber}&width=${this.computePageDimensions()}`;
    },
    async downloadProject() {
      const data = await imageStudioService
        .downloadImageStudioProject(this.project.id);

      saveAs(new Blob([data], { type: 'application/zip' }), `export_project_${this.project.id}.zip`);
    },

    async downloadImage(pageNumber) {
      // console.log(pageNumber);

      const data = await imageStudioService.downloadImageStudioPage(this.project.id, pageNumber);

      saveAs(new Blob([data], { type: 'image/jpeg' }), `${this.project.id}_${pageNumber}.jpg`);
    },

    captureInfo(index) {
      return `${this.project.captures[index].account_name} - ${this.project.captures[index].capture_id}`;
    },

    close() {
      this.infoDialog = false;
    },

    gotoCapture(index) {
      this.$router.push({
        name:   CAPTURE_PAGE,
        params: {
          captureId: this.project.captures[index].capture_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/variables";

.subHeader {
  color: $grey-darken-1;
}

</style>
