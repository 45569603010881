<template>
  <div
    ref="fullscreenDiv"
    class="video-wrapper"
    :class="[{ hideControl: !showFullscreen },{ drag: dragging },{ grab: !dragging }]"
    @mousemove="mouseMove($event)"
    @mousedown="dragStart($event)"
    @mouseup="dragEnd"
    @dblclick="doubleClick"
  >
    <v-img :src="src"
           :max-width="maxWidth"
           :max-height="maxHeight"
           :min-width="maxWidth"
           :min-height="maxHeight"
           :position="imagePosition"
    />
  </div>
</template>

<script>

export default {
  name:  'FullscreenImage',
  props: {
    src: {
      type:    String,
      default: '',
    },
    imgWidth: {
      type:    Number,
      default: 2048,
    },
    imgHeight: {
      type:    Number,
      default: 2048,
    },
  },
  data() {
    return {
      show:           false,
      showFullscreen: false,
      dragging:       false,
      lastMouseX:     -1,
      lastMouseY:     -1,
      left:           0,
      top:            0,
      zoom:           1.0,
    };
  },
  computed: {
    imagePosition() {
      // console.log(`left ${this.left}px top ${this.top}px`);

      return `left ${this.left}px top ${this.top}px`;
    },
    maxWidth() {
      return this.zoom * this.imgWidth;
    },
    maxHeight() {
      return this.zoom * this.imgHeight;
    },
  },
  mounted() {

  },
  methods: {
    toggleFullscreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else if (document.webkitFullscreenElement) {
        document.webkitExitFullscreen();
      } else if (this.$el.requestFullscreen) {
        this.$el.requestFullscreen();
      } else if (this.$el.webkitRequestFullscreen) {
        this.$el.webkitRequestFullscreen();
      }
      this.showFullscreen = true;
      this.$refs.fullscreenDiv.addEventListener('fullscreenchange', this.fullscreenHandler);
      this.$refs.fullscreenDiv.addEventListener('contextmenu', this.killContext);
    },
    fullscreenHandler() {
      if (!document.fullscreenElement) {
        this.showFullscreen = false;
        this.$refs.fullscreenDiv.removeEventListener('fullscreenchange', this.fullscreenHandler);
        this.$refs.fullscreenDiv.removeEventListener('contextmenu', this.killContext);
        this.$emit('fullscreen-exit');
      }
    },
    killContext(event) {
      event.preventDefault();
    },
    mouseMove(event) {
      if (this.dragging) {
        const dx = this.lastMouseX - event.clientX;
        const dy = this.lastMouseY - event.clientY;

        this.lastMouseX = event.clientX;
        this.lastMouseY = event.clientY;

        let x = this.left - dx;
        let y = this.top - dy;

        if (x > 0) {
          x = 0;
        }

        if (x < window.screen.width - (this.imgWidth * this.zoom)) {
          x = window.screen.width - (this.imgWidth * this.zoom);
        }

        if (y > 0) {
          y = 0;
        }

        if (y < window.screen.height - (this.imgHeight * this.zoom)) {
          y = window.screen.height - (this.imgHeight * this.zoom);
        }

        // console.log(`(${x}, ${y}) ${this.imgWidth}, ${this.imgHeight}`);
        this.left = x;
        this.top = y;
      }
    },

    dragEnd() {
      this.dragging = false;
    },
    dragStart(event) {
      if (event.button === 0) {
        this.lastMouseX = event.clientX;
        this.lastMouseY = event.clientY;
        this.dragging = true;
      } else if (event.button === 2) {
        this.zoom -= 0.25;
        if (this.zoom < 1.0) {
          this.zoom = 1.0;
        }
      }
    },

    doubleClick() {
      this.zoom += 0.25;

      if (this.zoom > 4.0) {
        this.zoom = 4.0;
      }

      this.dragging = false;
    },
  },
};
</script>
<style scoped lang="scss">

.video-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.video-wrapper.drag {
  cursor: grabbing;
}

.video-wrapper.grab {
  cursor: grab;
}

.video-wrapper.hideControl{
  display:none;
}

</style>
