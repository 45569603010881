import Vue from 'vue';

export default {
  /**
   * Get list of all preset frame profiles.
   *
   * @return {Array}
   */
  async getPresetFrameProfiles() {
    return Vue.axios
      .get('/api/preset_frame_profiles')
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Delete certain preset profile.
   *
   * @param {number} id - Profile identifier.
   */
  async deletePresetFrameProfile(id) {
    return Vue.axios
      .delete(`/api/preset_frame_profiles/${id}`)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Create new preset frame profile.
   * @param {object} data - Profile data.
   */
  async createPresetFrameProfile(data) {
    return Vue.axios
      .post('/api/preset_frame_profiles', data)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Update certain preset frame profile.
   * @param {number} id - Profile identifier.
   * @param {*} data - Profile data.
   */
  async updatePresetFrameProfile(id, data) {
    return Vue.axios
      .put(`/api/preset_frame_profiles/${id}`, data)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Get certain preset frame profile.
   * @param {number} id = Profile identifier.
   */
  async getPresetFrameProfile(id) {
    return Vue.axios
      .get(`/api/preset_frame_profiles/${id}`)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },
};
