<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    @click:outside="close()"
  >
    <v-card>
      <v-card-title class="text-h6 white--text primary">
        {{ $t('capturePage.captureInfoTitle') }}
        <v-spacer />
        <v-btn
          icon
          dark
          @click="close()"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="currentTab">
          <v-tab
            v-for="(tab, index) in tabs"
            :key="index"
            :href="`#tab-${index}`"
          >
            {{ tab }}
          </v-tab>
          <v-tab-item v-for="(tab, index) in tabs"
                      :key="index"
                      :value="`tab-${index}`"
          >
            <v-card flat
                    tile
            >
              <v-card-text>
                <v-combobox
                  v-model="selectedTags"
                  :items="tags"
                  item-text="name"
                  item-value="id"
                  color="secondary"
                  item-color="secondary"
                  :label="$t('capturePage.filterTags')"
                  :search-input.sync="tagSearch"
                  multiple
                  chips
                  deletable-chips
                  @input="autoInput"
                >
                  <template #no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('capturePage.createTag') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
                <v-text-field
                  v-for="(setting, idx) in index === 0 ? captureInfo : cameraInfo"
                  :key="idx"
                  :value="textValue(index, setting.key)"
                  :label="setting.title"
                  readonly
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
        <v-card-text />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import config from '../../js/config';
import tagsService from '../../js/services/tagsService';

export default {
  name:  'CaptureDetailsInfo',
  props: {
    capture: {
      type:    Object,
      default: () => {},
    },
    showDialog: {
      type:    Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTab:   null,
      tabs:         [],
      tags:         [],
      selectedTags: [],
      tagSearch:    '',
      dialog:       false,
      captureInfo:  [
        { key: 'id', title: 'Id' },
        { key: 'account_name', title: 'Account Name' },
        { key: 'customer_location', title: 'Location' },
        { key: 'local_date_created', title: 'Local Create Time' },
        { key: 'location_timezone', title: 'Timezone' },
        { key: 'machine_name', title: 'Machine' },
        { key: 'username', title: 'Username' },
        { key: 'scan_mode', title: 'Scan Mode' },
        { key: 'xoffset', title: 'X Offset' },
        { key: 'yoffset', title: 'Y Offset' },
      ],
      cameraInfo: [
        { key: 'width', title: 'Frame Width' },
        { key: 'height', title: 'Frame Height' },
        { key: 'camera_serial', title: 'Serial Number' },
        { key: 'camera_height', title: 'Height (in)' },
        { key: 'camera_position', title: 'Position' },
        { key: 'frame_count', title: 'Frame Count' },
        { key: 'skipped_frames', title: 'Skipped Frames' },
        { key: 'auto_wb', title: 'Auto White Balance' },
        { key: 'wb_r', title: 'White Balance Red' },
        { key: 'wb_b', title: 'White Balance Blue' },
        { key: 'wb_g', title: 'White Balance Green' },
        { key: 'auto_exposure', title: 'Auto Exposure' },
        { key: 'exposure', title: 'Exposure' },
        { key: 'auto_exposure_depth', title: 'Auto Exposure Depth' },
        { key: 'exposure_depth', title: 'Exposure Depth' },
        { key: 'auto_gain', title: 'Auto Gain' },
        { key: 'gain', title: 'Gain' },
        { key: 'enable_color_correction', title: 'Color Correction' },
      ],
    };
  },
  watch: {
    showDialog(val) {
      this.dialog = val;
    },
    capture: {
      deep: true,
      handler() {
        this.selectedTags = this.capture.tag_names;
      },
    },
  },
  async mounted() {
    this.tabs.push(this.$t('capturePage.captureInfoTab'));

    this.capture.camera_settings.forEach((setting, index) => {
      this.tabs.push(`${this.$t('capturePage.cameraInfoTab')} ${index}`);
    });

    this.tags = await tagsService.loadTags();
    // this.selectedTags = this.capture.tag_names.map(t => t.name);
    this.selectedTags = this.capture.tag_names;
  },
  methods: {
    textValue(index, key) {
      if (index === 0) {
        if (key === 'local_date_created') {
          return this.$moment(this.capture[key]).format(config.dateTimeFormat);
        }

        if (key === 'scan_mode') {
          return this.$t(`capturePage.scanModes[${this.capture[key]}]`);
        }

        return this.capture[key];
      }

      return this.capture.camera_settings[index - 1][key];
    },
    close() {
      this.dialog = false;
      this.$emit('closed');
    },
    async autoInput(val) {
      // Check if we are deleting a tag
      if (val.length < this.capture.tag_names.length) {
        const delItems = this.capture.tag_names.filter(ct => val.every(s => s.id !== ct.id));

        delItems.forEach(async t => {
          await tagsService.deleteCaptureTagAssociation({
            capture_id: this.capture.id,
            tag_id:     t.id,
          });
          this.$emit('refresh');
        });
      } else {
        // We are adding a tag
        const addItems = val.filter(ct => this.capture.tag_names.every(s => s.id !== ct.id));

        addItems.forEach(async t => {
          if (t.id) {
            await tagsService.createCaptureTagAssociation({
              capture_id: this.capture.id,
              tag_id:     t.id,
            });
            this.$emit('refresh');
          } else {
            await tagsService.createCaptureTagAssociation({
              capture_id: this.capture.id,
              tag_descr:  t,
            });
            this.tags = await tagsService.loadTags();
            this.$emit('refresh');
          }
        });
      }
      this.tagSearch = '';
    },
  },

};
</script>

<style lang="scss" scoped>
  @import '../../css/variables';

  .title {
    background-color: $blue-title;
  }
</style>
