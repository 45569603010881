var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0 px-12"},[_c('v-row',[_c('v-breadcrumbs',{attrs:{"large":"","items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"to":item.to,"disabled":item.disabled}},[(item.disabled)?_c('h1',{staticClass:"subHeader"},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('h1',{staticClass:"breadcrumbLink"},[_vm._v(" "+_vm._s(item.text)+" ")])])]}}])})],1),_c('v-row',{staticClass:"text-center d-flex",attrs:{"id":"menu-bar"}},[_c('v-col',{staticClass:"justify-space-between"}),_c('v-col',{staticClass:"justify-space-between"},[_c('v-btn-toggle',{attrs:{"color":"secondary","group":"","mandatory":""},model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"orange--text text--darken-3",attrs:{"large":"","value":_vm.views.comparison}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" video_label ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('videoStudio.compareTooltip'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"orange--text text--darken-3",attrs:{"large":"","value":_vm.views.playlistComparison}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" playlist_play ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('videoStudio.playlistTooltip'))+" ")])])],1)],1),_c('v-col',{staticClass:"justify-space-between"},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-speed-dial',{staticClass:"mr-3",attrs:{"direction":"bottom","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[(!_vm.showOverlayMenu)?_c('v-icon',[_vm._v(" info ")]):_c('v-icon',[_vm._v(" cancel ")])],1)]},proxy:true}]),model:{value:(_vm.showOverlayMenu),callback:function ($$v) {_vm.showOverlayMenu=$$v},expression:"showOverlayMenu"}},[_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.selectedOverlayOptions),callback:function ($$v) {_vm.selectedOverlayOptions=$$v},expression:"selectedOverlayOptions"}},[_c('v-list-item',{attrs:{"color":"primary","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('videoStudio.overlayOptionsMenuTitle'))+" ")]),_vm._l((_vm.overlayOptions),function(option){return [_c('v-list-item',{key:option.key,attrs:{"value":option.key,"active-class":"secondary--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(option.name)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"secondary accent-4"}})],1)]}}],null,true)})]})],2)],1)],1),_c('v-btn',{ref:"saveBtn",staticClass:"secondary white--text ml-3",on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('videoStudio.save'))+" ")])],1)])],1),_c('v-row',[_c('v-col',{staticClass:"pa-0"},[(_vm.view === _vm.views.comparison)?_c('VideoCompareSideBySide',{ref:_vm.views.comparison,attrs:{"max-width":"1100","videos":_vm.videos,"show-date":_vm.dateSelected,"show-timestamp":_vm.timestampSelected},on:{"videos-update":_vm.videosUpdate,"save-error":_vm.saveError,"save-completed":_vm.saveCompleted}}):_c('VideoComparePlaylist',{ref:_vm.views.playlistComparison,attrs:{"videos":_vm.videos,"show-date":_vm.dateSelected,"show-timestamp":_vm.timestampSelected},on:{"videos-update":_vm.videosUpdate,"save-error":_vm.saveError,"save-completed":_vm.saveCompleted}})],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackBarTimeout,"color":"primary"},model:{value:(_vm.saveFinished),callback:function ($$v) {_vm.saveFinished=$$v},expression:"saveFinished"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('videoStudio.saveFinished'))+" ")])]),_c('v-snackbar',{attrs:{"timeout":_vm.snackBarTimeout,"color":"primary"},model:{value:(_vm.saveStarted),callback:function ($$v) {_vm.saveStarted=$$v},expression:"saveStarted"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('videoStudio.saveStarted'))+" ")])]),_c('v-snackbar',{attrs:{"timeout":_vm.snackBarTimeout,"color":"error"},model:{value:(_vm.saveLocalError),callback:function ($$v) {_vm.saveLocalError=$$v},expression:"saveLocalError"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('videoStudio.saveError'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }