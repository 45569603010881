<template>
  <v-container
    class="tabBackground"
  >
    <v-row>
      <v-col
        class="d-flex justify-end"
      >
        <v-btn
          color="secondary"
          class="mx-2"
          @click="openAddDialog()"
        >
          {{ $t('presetFrameProfiles.add') }}
        </v-btn>
        <v-btn
          class="mx-2"
          :disabled="selectedProfile.length === 0"
          @click="openUpdateDialog()"
        >
          {{ $t('presetFrameProfiles.edit') }}
        </v-btn>
        <v-btn
          class="mx-2"
          :disabled="selectedProfile.length === 0"
          @click="openDeleteDialog()"
        >
          {{ $t('presetFrameProfiles.delete') }}
        </v-btn>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-row>
      <v-col cols="1" />
      <v-col cols="10">
        <v-data-table
          v-model="selectedProfile"
          :headers="headers"
          :items="profiles"
          :loading="loading"
          item-key="id"
          color="grey darken-1"
          show-select
          single-select
          sort-by="name"
        />
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-dialog
      v-model="deleteDialog"
      persistent
      width="unset"
    >
      <v-card class="tabDialog">
        <v-card-title class="text-h6 white--text primary">
          {{ $t('presetFrameProfiles.deleteDialog.title') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <div class="text-h6 my-4">
                {{ $t('presetFrameProfiles.deleteDialog.message') }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secondary"
            @click="deleteProfile()"
          >
            {{ $t('presetFrameProfiles.deleteDialog.submit') }}
          </v-btn>
          <v-btn @click="cancelDelete()">
            {{ $t('presetFrameProfiles.deleteDialog.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addDialog"
      persistent
      width="unset"
    >
      <ValidationObserver v-slot="{ invalid, handleSubmit }">
        <v-card class="tabDialog">
          <v-card-title class="text-h6 white--text primary">
            {{
              isUpdateMode ?
                $t('presetFrameProfiles.updateDialog.title'):
                $t('presetFrameProfiles.addDialog.title')
            }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <ValidationProvider
                  name="name"
                  rules="required|max:255"
                >
                  <v-text-field
                    v-model="newProfile.name"
                    :label="$t('presetFrameProfiles.addDialog.name')"
                  />
                </ValidationProvider>
                <ValidationProvider
                  name="preset_frames"
                  rules="required"
                >
                  <v-select
                    v-model="newProfile.preset_frames"
                    :items="availablePresetFrames"
                    :label="$t('presetFrameProfiles.addDialog.presetFrames')"
                    chips
                    multiple
                    required
                  >
                    <template #selection="{ attrs, item, select, selected }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        class="secondary"
                        color="white"
                        close
                        @click="select"
                        @click:close="removePreset(item)"
                      >
                        {{ item }}
                      </v-chip>
                    </template>
                  </v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="secondary"
              :disabled="invalid"
              @click="isUpdateMode?handleSubmit(updateProfile):handleSubmit(addProfile)"
            >
              {{
                isUpdateMode ?
                  $t('presetFrameProfiles.updateDialog.submit'):
                  $t('presetFrameProfiles.addDialog.submit')
              }}
            </v-btn>
            <v-btn @click="cancelAdd()">
              {{ $t('presetFrameProfiles.addDialog.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
    <v-snackbar
      v-model="deleteSuccessSnackbar"
      color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('presetFrameProfiles.deleteDialog.success') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="deleteErrorSnackbar"
      color="error"
    >
      <div class="font-weight-bold">
        {{ $t('presetFrameProfiles.deleteDialog.error') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="addSuccessSnackbar"
      color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('presetFrameProfiles.addDialog.success') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="addErrorSnackbar"
      color="error"
    >
      <div class="font-weight-bold">
        {{ $t('presetFrameProfiles.addDialog.error') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="updateSuccessSnackbar"
      color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('presetFrameProfiles.updateDialog.success') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="updateErrorSnackbar"
      color="error"
    >
      <div class="font-weight-bold">
        {{ $t('presetFrameProfiles.updateDialog.error') }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import presetFrameProfilesService from '../../js/services/presetFrameProfilesService';

export default {
  name:       'PresetFrameProfiles',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: 'secondary',
      headers: [
        {
          text:  this.$t('presetFrameProfiles.profilesTable.id'),
          align: 'center',
          value: 'id',
        },
        {
          text:  this.$t('presetFrameProfiles.profilesTable.name'),
          align: 'center',
          value: 'name',
        },
        {
          text:  this.$t('presetFrameProfiles.profilesTable.frames'),
          align: 'center',
          value: 'preset_frames',
        },
      ],
      profiles:              [],
      selectedProfile:       [],
      deleteDialog:          false,
      deleteSuccessSnackbar: false,
      deleteErrorSnackbar:   false,
      addDialog:             false,
      addSuccessSnackbar:    false,
      addErrorSnackbar:      false,
      isUpdateMode:          false,
      updateErrorSnackbar:   false,
      updateSuccessSnackbar: false,
      newProfile:            {
        name:          '',
        preset_frames: [],
      },
      availablePresetFrames: Array.from({ length: 361 }, (_, i) => i + 1),
    };
  },

  created() {
    this.loadProfies();
  },

  methods: {
    /**
     * Load preset frame profiles.
     */
    async loadProfies() {
      this.loading = 'secondary';
      this.profiles = await presetFrameProfilesService.getPresetFrameProfiles();
      this.loading = false;
    },

    /**
      * Delete selected preset frame profile.
      */
    async deleteProfile() {
      try {
        await presetFrameProfilesService.deletePresetFrameProfile(this.selectedProfile[0].id);
        this.deleteSuccessSnackbar = true;
        this.loadProfies();
        this.selectedProfile = [];
      } catch {
        this.deleteErrorSnackbar = true;
      }

      this.deleteDialog = false;
    },

    openDeleteDialog() {
      this.deleteDialog = true;
    },

    cancelDelete() {
      this.deleteDialog = false;
    },

    openAddDialog() {
      this.newProfile.name = '';
      this.newProfile.preset_frames = [];
      this.isUpdateMode = false;
      this.addDialog = true;
    },

    openUpdateDialog() {
      this.getPresetProfile();
      this.isUpdateMode = true;
      this.addDialog = true;
    },

    cancelAdd() {
      this.addDialog = false;
    },

    removePreset(preset) {
      this.newProfile.preset_frames = this.newProfile.preset_frames.filter(p => p !== preset);
    },

    /**
     * Add new preset frame profile.
     */
    async addProfile() {
      try {
        await presetFrameProfilesService.createPresetFrameProfile(this.newProfile);
        this.addSuccessSnackbar = true;
      } catch {
        this.addErrorSnackbar = true;
      }
      this.addDialog = false;
      this.loadProfies();
    },

    /**
     * Update certain preset frame profile.
     */
    async updateProfile() {
      try {
        await presetFrameProfilesService
          .updatePresetFrameProfile(this.selectedProfile[0].id, this.newProfile);
        this.updateSuccessSnackbar = true;
      } catch {
        this.updateErrorSnackbar = true;
      }
      this.addDialog = false;
      this.loadProfies();
    },

    /**
     * Get certain preset frame profile.
     */
    async getPresetProfile() {
      this.newProfile = await presetFrameProfilesService
        .getPresetFrameProfile(this.selectedProfile[0].id);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../css/variables';
  @import '../../css/components/_settingsTab';
</style>
