<template>
  <v-app-bar color="$vuetify.themes[theme].headerBackground"
             app
             clipped-left
             clipped-right
  >
    <v-app-bar-nav-icon color="secondary"
                        @click="navBarToggle"
    />
    <v-img src="@/assets/logo.png"
           max-height="50"
           max-width="274"
           class="mx-4"
           contain
    />
    <v-combobox v-model="selectedAccount"
                :items="accountNames"
                :search-input.sync="searchText"
                solo-inverted
                :label="$t('header.search')"
                class="mt-8 mx-8 px-8"
                @keydown="searchInput"
                @change="searchAccount"
    />
    <v-spacer />
    <v-menu auto
            offset-y
            open-on-click
    >
      <template #activator="{ on }">
        <v-btn

          v-on="on"
        >
          <v-avatar size="32px"
                    item
                    class="mr-1"
          >
            <img v-if="user && user.image"
                 :src="user.image"
                 class="userHeaderAvatar"
            >
            <img v-else
                 src="@/assets/user-logo.png"
                 alt="User"
            >
          </v-avatar>
          <span>{{ user.username }}</span>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="goToUser">
          <v-list-item-title>{{ $t('header.account') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title>{{ $t('header.logOut') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="studioConfirmation.showDialog"
      persistent
      width="unset"
    >
      <v-card>
        <v-card-title class="test-h6 white--text primary">
          {{ $t('studioConfirmationDialog.title') }}
        </v-card-title>
        <v-card-text>
          <div class="mt-4">
            <div
              v-if="studioConfirmation.isImageStudio"
            >
              {{ $t('studioConfirmationDialog.imageStudioDescription') }}
            </div>
            <div
              v-if="studioConfirmation.isVideoStudio"
            >
              {{ $t('studioConfirmationDialog.videoStudioDescription') }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            @click="submitLogout"
          >
            {{ $t('header.logOut') }}
          </v-btn>
          <v-btn @click="closeConfirmationDialog">
            {{ $t('globalActions.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import authService from '../js/services/authService';
import userService from '../js/services/userService';
import accountsService from '../js/services/accountsService';
import imageStudioService from '../js/services/imageStudioService';
import videoStudioService from '../js/services/videoStudioService';
import {
  USER_PAGE, LOGIN_PAGE, ACCOUNT_PAGE, ACCOUNTS_PAGE,
} from '../js/router/pages';

export default {
  name: 'HeaderToolBar',
  data() {
    return {
      menuItems: [
        { title: 'Account Settings', action: 'goToAccount' },
        { title: 'Logout', action: 'logout' },
      ],
      accountNames:       [],
      selectedAccount:    null,
      searchText:         '',
      studioConfirmation: {
        showDialog:    false,
        isImageStudio: false,
        isVideoStudio: false,
      },
    };
  },
  computed: {
    /**
     * Returns logged user.
     *
     * @return {null|Object}
     */
    user() {
      return userService.getAuthUser();
    },

  },
  watch: {
    /**
     * Search account's names for autocomplete.
     */
    searchText: {
      async handler() {
        this.accountNames = await accountsService.loadAccountNames(this.searchText);
      },
    },
  },
  methods: {
    navBarToggle() {
      this.$emit('toggle-navbar');
    },

    /**
     * Show logout confirmation dialog if there are images / videos in studio.
     */
    logout() {
      const imageStudioHasImages = imageStudioService.getImagesCount() > 0;
      const videoStudioHasVideos = videoStudioService.getVideosCount() > 0;

      if (imageStudioHasImages || videoStudioHasVideos) {
        this.studioConfirmation.isImageStudio = imageStudioHasImages;
        this.studioConfirmation.isVideoStudio = videoStudioHasVideos;
        this.studioConfirmation.showDialog = true;
      } else {
        this.submitLogout();
      }
    },

    /**
     * Logout user and redirect to the login page.
     */
    submitLogout() {
      authService.logout();
      this.$router.push({ name: LOGIN_PAGE });
    },

    closeConfirmationDialog() {
      this.studioConfirmation.showDialog = false;
    },

    /**
     * Go to user's account page.
     */
    goToUser() {
      this.$router.push({ name: USER_PAGE });
    },

    /**
     * Search account by text or selected account.
     */
    searchAccount(account) {
      if (account && account.value) {
        this.goToAccountPage(account.value);
        this.$nextTick(() => {
          this.searchText = '';
          this.selectedAccount = null;
        });
      }
    },

    searchInput(key) {
      if (key.key === 'Enter') {
        this.$router.push({
          name:  ACCOUNTS_PAGE,
          query: {
            search_term: this.searchText,
          },
        });
      }
    },

    /**
     * Redirect to selected account page.
     *
     * @param {number|Object} id - Account identifier.
     */
    goToAccountPage(id) {
      this.$router.push({
        name:   ACCOUNT_PAGE,
        params: {
          accountId: id,
        },
      }).catch(err => err);
    },
  },
};
</script>

<style scoped>

.v-list {
  padding: 0px;
}

.v-menu__content {
  margin-left: -25px;
}
.userHeaderAvatar{
  object-fit: contain;
  width: 80px;
  height: 80px;
}
</style>
